import { Component, OnInit, AfterViewInit, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Day } from '../../interfaces/day';
import { Swap } from '../../interfaces/swap';
import { Shift } from '../../interfaces/shift';
import { Dayjs, OpUnitType } from 'dayjs';
import * as dayjs from 'dayjs';
import { iSelfSchedulingJoker, iSelfSchedulingProposal } from '../../../../../shared/self-scheduling/data';

export enum CalendarViewMode {
  Month = 'month',
  Week = 'week',
  Slider = 'slider',
}

interface Calendar {
  [index: number]: CalendarWeek;
}

interface CalendarWeek {
  days: CalendarDay[];
}

interface CalendarDay {
  date: Date;
  key: string;
  hasRequest: boolean;
  hasJoker: boolean;
  isToday: boolean;
  isOtherMonth: boolean;
  isTomorrow: boolean;
  isWeekend: boolean;
  isSelected: boolean;
}

export interface CalendarEvent {
  startTime: string;
  endTime: string;
  isAllDay: boolean;
  type: string;
  cssClasses?: string[];
  label?: string;
}

type DateRangeItem = {
  amount: number;
  range: OpUnitType;
}

type DataRange = {
  [K in CalendarViewMode]: DateRangeItem
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() viewMode: CalendarViewMode = CalendarViewMode.Month;
  @Input() showNavigation: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() showCalendar: boolean = false;
  @Input() showSwitch: boolean = true;
  @Input() schedule: Day[] = [];
  @Input() events: CalendarEvent[] = [];
  @Input() currentDate: string = dayjs().format();
  @Input() cssClass?: string = '';

  @Output() monthChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() weekChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() datePressed: EventEmitter<string> = new EventEmitter<string>();

  dataRanges: DataRange = {
    [CalendarViewMode.Month]: {
      amount: 1,
      range: 'month'
    },
    [CalendarViewMode.Week]: {
      amount: 1,
      range: 'week'
    },
    [CalendarViewMode.Slider]: {
      amount: 1,
      range: 'month'
    },
  }
  daysInWeek = 7;
  calendarWeekendDays = [0, 6];
  translations: any = {};
  date: Dayjs;
  calendar: Calendar = [];
  requests = {};
  shifts = {};
  eventList = {};
  isSlider = false;
  isMonth = false;
  isWeek = false;
  previousMonth: string;
  nextMonth: string;
  today: Dayjs;
  tomorrow: Dayjs;

  @HostBinding('class') get cssClasses() {
    return `
      ${(this.showCalendar ? 'show-calendar' : 'hide-calendar')}
      ${(this.showHeader ? 'show-header' : 'hide-header')}
      ${(this.showNavigation ? 'show-navigation' : 'hide-navigation')}
      ${(this.cssClass ? this.cssClass : '')}
    `;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) {
    this.translate.get([
      'schedule.noShift',
      'calendar.weekDays.0',
      'calendar.weekDays.1',
      'calendar.weekDays.2',
      'calendar.weekDays.3',
      'calendar.weekDays.4',
      'calendar.weekDays.5',
      'calendar.weekDays.6',
    ]).subscribe(translations => {
      this.translations = translations;
    })
  }

  toggleShowCalendar = () => this.showCalendar = !this.showCalendar;

  generateWeek(date: Dayjs) {
    const days = Array(this.daysInWeek);
    for(let i = 0; i < this.daysInWeek; i++) {
      days[i] = this.generateDay(dayjs(date).add(i, 'day'));
    }
    return {days};
  }

  generateDay(date: Dayjs) {
    const calendarItem: any = {
      date: date.toDate(),
      key: date.format('YYYYMMDD')
    };

    if (this.requests[calendarItem.key]) calendarItem.hasRequest = true;
    if (date.isSame(this.today, 'day')) calendarItem.isToday = true;
    if (!date.isSame(this.date, 'month')) calendarItem.isOtherMonth = true;
    if (date.isSame(this.tomorrow, 'day')) calendarItem.isTomorrow = true;
    if (this.calendarWeekendDays.includes(date.day())) calendarItem.isWeekend = true;
    if (date.isSame(this.date, 'day')) calendarItem.isSelected = true;
    
    return calendarItem;
  }

  generateMonth() {    
    const newCalendar = [];
    
    let startDay: Dayjs;
    let endDay: Dayjs;

    if (this.viewMode === CalendarViewMode.Month) {
      startDay = this.date.startOf('month').startOf('isoWeek').startOf('day');
      endDay = this.date.endOf('month').endOf('isoWeek').endOf('day');
    } else if(this.viewMode === CalendarViewMode.Week) {
      startDay = this.date.startOf('isoWeek').startOf('day');
      endDay = this.date.endOf('isoWeek').endOf('day');
    } else if(this.viewMode === CalendarViewMode.Slider) {
      startDay = this.date.startOf('month').startOf('day');
      endDay = this.date.endOf('month').endOf('day');

      this.previousMonth = startDay.clone().subtract(1, 'month').endOf('month').startOf('day').format();
      this.nextMonth = startDay.clone().add(1, 'month').startOf('month').startOf('day').format();
    }

    const weeks = endDay.diff(startDay, 'week') + 1;

    for(let i = 0; i < weeks; i++) {
      let date = startDay.add(i, 'week')
      if (date.isBefore(endDay)){
        let week = this.generateWeek(date);
        newCalendar.push(week);
      }
    }

    this.processEvents();

    this.calendar = newCalendar;
  }

  ngOnInit(): void {
    this.today = dayjs();
    this.tomorrow = dayjs().add(1, 'day');
    this.isMonth = (this.viewMode === CalendarViewMode.Month)
    this.isWeek = (this.viewMode === CalendarViewMode.Week)
    this.isSlider = (this.viewMode === CalendarViewMode.Slider)
    
    if (this.isSlider) {
      this.showCalendar = true;
    }
  }

  processEvents(): void {
    this.eventList = {};

    this.events.forEach(event => {
      const key = dayjs(event.startTime).format('YYYYMMDD');
      if (!this.eventList[key]) this.eventList[key] = [];
      this.eventList[key].push(event);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.date = dayjs(this.currentDate);
    this.shifts = {};
    this.requests = {};

    this.schedule.filter((day: Day) => day.shifts).forEach((day: Day) => {
      day.shifts.filter(shift => shift.resourceId !== 0).forEach((shift: Shift) => {
        const key = dayjs(shift.startTime).format('YYYYMMDD');
        if (!this.shifts[key]) this.shifts[key] = [];
        this.shifts[key].push(shift);
        if (shift.swaps) {
          shift.swaps.filter(swap => swap.shiftRequest).forEach((swap: Swap) => {
            const key = dayjs(swap.shiftRequest.startTime).format('YYYYMMDD');
            if (!this.requests[key]) this.requests[key] = [];
            this.requests[key].push(swap);
          });
        }
      });
    });

    this.generateMonth();

    if (this.isSlider) {
      setTimeout(this.initSliderScrolling.bind(this), 500);
    }
  }

  initSliderScrolling(): void {
    const selectedDayElement: any = this.document.querySelector('.day.selected');
    if (selectedDayElement) {
      const monthElement: any = this.document.querySelector('.month');
      const dayElementWidth = selectedDayElement.clientWidth;
      const scrollAmount = (selectedDayElement.offsetLeft - selectedDayElement.scrollLeft + selectedDayElement.clientLeft) - (dayElementWidth * 3);
  
      if (monthElement) monthElement.scrollLeft = scrollAmount;
    }
  }

  ngAfterViewInit(): void {
    if (this.isSlider) {
      setTimeout(this.initSliderScrolling.bind(this), 500);
    }
  }

  getDayCssClasses(day: CalendarDay) {
    let classes = 'day';
    if (day.isToday)      classes += ' today';
    if (day.isTomorrow)   classes += ' tomorrow';
    if (day.isWeekend)    classes += ' weekend';
    if (day.isOtherMonth) classes += ' other-month';
    if (day.hasRequest)   classes += ' request';
    if (day.hasJoker)     classes += ' joker';
    if (day.isSelected)   classes += ' selected';
    return classes;
  }

  calculateTimePercentage(date: Date | string, begin?: Date | string) {
    if (typeof date === 'string') date = dayjs(date).toDate();
    if (begin && typeof begin === 'string') begin = dayjs(begin).toDate();

    const startOfDay = new Date(date.getTime());
    startOfDay.setHours(0, 0, 0, 0);

    let percentage = ((date.getTime() - startOfDay.getTime()) / (1000 * 60 * 60 * 24) * 100);

    if (begin) {
      percentage = 100 - percentage;
      if (!dayjs(date).isSame(begin, 'day')) {
        percentage = percentage === 100 ? 0 : -(100 - percentage);
      }
    }

    return percentage;
  }

  pressDate(date: string) {
    console.log('DATE PRESSED', date);
    
    this.datePressed.emit(date);
  }

  navigateCalendar(backwards: boolean = false) {
    const dataRange = this.dataRanges[this.viewMode];

    this.date = backwards 
      ? this.date.clone().subtract(dataRange.amount, dataRange.range) 
      : this.date.clone().add(dataRange.amount, dataRange.range);

    if (this.viewMode === CalendarViewMode.Month) {
      this.monthChanged.emit(this.date.format());
    } else if (this.viewMode === CalendarViewMode.Week ) {
      this.weekChanged.emit(this.date.format());
    }
  }

}
